
import React from 'react'
import ProthamaBuildingelevation from "../images/Final Elevation page.jpg";


function Slider() {
  return (
    <div>

<div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
 
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={ProthamaBuildingelevation} className="d-block w-100" alt="bestflatrajbarikrishnagar"/>
      <div className="carousel-caption d-none d-md-block">
   
      </div>
    </div>
    
   
  </div>
 
</div>



    </div>
  )
}

export default Slider