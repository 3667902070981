import { useState } from 'react';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {Link} from "react-router-dom";
import Navbar from '../inc/Navbar'
import Conectivity from './Conectivity'
import ScrollToTop from "react-scroll-to-top";

import AddEnquiry from '../AddEnquiry';
function Conectivitypage() {

        const [show, setShow] = useState(false);
    
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    
  return (
    <div>
        <Navbar />
        <br/>
        <br/>
        <br />
        <br />
        <br/>
        <br/>
        
        <div style={{ color: "white", padding: "8px;", margin: 3 }} className="fixed-bottom "> <p   className=""> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>   
      
        <Button variant="success" onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg><b className='blink'> Contact us </b><div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
      
        </Modal.Header>
        <Modal.Body>
        <center>
            <h5 style={{ color: "darkBlue" }} className='mb-2'>আপনার ভালোবাসার ঠিকানা  </h5>
            <h4 style={{ color: "maroon" }}>ফ্ল্যাট  এর দামে আধুনিক জীবনযাপনের সমস্ত সুবিধা সহ রাজ প্রাসাদে বাস করুন। </h4>
          </center>
        <center>
            <div className="album py-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">


                    <AddEnquiry />
                  </div>

                </div>
              </div>
            </div>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
     
        </Modal.Footer>
      </Modal>
        </p></div>
        <ScrollToTop smooth top="100" color="maroon"/>
      
        <div className='flatkrishnagarnadianew'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Conectivity</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
        <Conectivity/>
        <br/>
    </div>
  )
}

export default Conectivitypage