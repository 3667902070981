import React, { useState, useEffect } from 'react';






import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import ScrollToTop from "react-scroll-to-top";
import "./Home.css";
import Flatbhkprice from './Flatbhkprice';
import Amenities from './Amenities';
import Conectivity from './Conectivity';
import Workp from './Workp';
// import AddEnquiry from '../AddEnquiry';
// import Prothamakrishnagar from "../images/bannar-english.jpeg";
// import Prothamaflat from "../images/bannar-bengali.jpeg";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import sbilogo from "../images/sbi logo.jpg";
import iobbank from "../images/IOB bank logo.jpg";

import Modal from 'react-modal';
import AddEnquiry from '../AddEnquiry';

function Home() {
  




  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => setModalOpen(false);


  return (
    <div>
      <Navbar />
      <br />

      <br />



      <div className="album py-1">
        <div className="container">
          <h6>Prothama </h6>
        </div>
      </div>

      <br />
      <br />



      <div className='nnew'>

        <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
          <div className="album py-2">
            <div className="container">
              <div className="row">
                <br />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          
          <div className="d-flex justify-content-end align-items-center mb-3">
            <button className='closepopupbutton mt-2' onClick={closeModal}>Close <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg></button>
          </div>
          <center>
            <h5 style={{ color: "darkBlue" }} className='mb-2'>আপনার ভালোবাসার ঠিকানা  </h5>
            <h2 style={{ color: "maroon" }}>ফ্ল্যাট  এর দামে আধুনিক জীবনযাপনের সমস্ত সুবিধা সহ রাজ প্রাসাদে বাস করুন। </h2>
          </center>
          <center>
            <div className="album py-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">


                    <AddEnquiry />
                  </div>

                </div>
              </div>
            </div>
          </center>
          <br />

          
        </Modal>
      </div>


      <div className="flatkrishnagarnadiawb">
        <div className="album py-2">
          <div className="container">
            <div className="row ">
              <div className="col-md-12" >

                <center>
                  <div className="flatkrishnagarnadiawb">
                    <h2 className='mb-1 mt-3'> প্রথমা রাজপ্রাসাদ </h2>

                    <h5 className='mb-2'>আপনার ভালোবাসার ঠিকানা  </h5>
                    <p className='mb-1'>  ফ্ল্যাট  এর দামে আধুনিক জীবনযাপনের সমস্ত সুবিধা সহ রাজ প্রাসাদে বাস করুন। </p>
                  </div>
                </center>

              </div>

              <div className="d-flex justify-content-between align-items-center">

              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider />
      <ScrollToTop smooth top="100" color="maroon" />



      <div className='colorback'>
        <marquee><h4 className='mt-2' style={{ color: "white" }} >Welcome To Prothama Lifespace | Rera Approved Project, Reg. no: WBRERA/P/NAD/2024/000145  </h4></marquee>
      </div>




<a href='book_now'>
      <div style={{ color: "white", padding: "1px;", margin: 3 }} className="fixed-bottom "> <p   className="buttonhome "> 
        
        
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>  <b className='blink'> Contact us <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div> </b>
      
 
        </p></div>
        </a>


      <div className="album py-0">

        <div className="container mt-3">
          <div className='colornew'>
            <center>
              <h5> <h5 className='blink' style={{ color: 'darkBlue' }}> Rera Approved Project</h5 > Reg. no: <b style={{ color: "maroon" }}> WBRERA/P/NAD/2024/0001454 </b>  www.rera.wb.gov.in</h5>
            </center>
          </div>
        </div>
      </div>
      <div className="album py-0">
        <div className="container">
          <div className='newbackhome'>
            <center>
              <h4 style={{ colo: "white" }} className='mt-2'> <b>Project Location : <br /></b> <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              </svg> 50, R.N Tagore Road, Krishnagar (Opposite Krishnagar Rajbari) <br />Pin - 741101, Dist.- Nadia |  WB  | INDIA </h4>
            </center>
          </div>

        </div>
      </div>
      <br />
      <div className="album py-0">
        <div className="container">
          <div className='colortext'>
            <h3><b>Approved</b> Bankers</h3>
          </div>
          <div className="row">

            <div className="col-md-6">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="col-md-6 me-2 mt-3">

                  <div className="card">
                    <Zoom>
                      <img src={sbilogo} className="card-img-top" alt="sbilogo" />
                    </Zoom>

                  </div>
                </div>
                <div className="col-md-6  mt-3">

                  <div className="card">
                    <Zoom>
                      <img src={iobbank} className="card-img-top" alt="iobbank logo" />
                    </Zoom>

                  </div>

                </div>

              </div>
            </div>


            <div className="col-md-6">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="col-md-12 me-2 mt-3">

                  <div className="card">
                    <div className='btn-grad49 mb-2 '><b style={{ color: "red" }} className='blink'>100 + Flat</b> <div class="spinner-grow text-danger" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div></div>
                    <div className="card-body">
                      <div className='backmarquee'>
                        <marquee><h4 className='mt-2' style={{ color: "white" }} > <a style={{ color: "white" }} href='tel: 9434054859'>Book Now: +91 94340 54859</a> </h4></marquee>
                      </div>


                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <Flatbhkprice />
      <br />
      <div className="album py-2">
        <div className="container">
          <h3 style={{ color: "darkBlue" }}><b>FLAT DETAILS :</b></h3>
        </div>
      </div>
      <div className="album py-1">
        <div className="container">

          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <b>Flat details </b>
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg>   B+G+6+STORIED Building </h6>
                  <h6><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> 1BHK, 2BHK, 3BHK Available</h6>
                  <h6><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Commercial Cum - Residential Building</h6>


                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <b>Area of Flat</b>
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">

                  <h6>390 Sq. Feet to 1160 Sq. Feet</h6>

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <b>Rera No. </b>
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6>RERA Approved Project  |  Reg. No: WBRERA/P/NAD/2024/001454</h6>

                </div>
              </div>
            </div>


            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  <b>Amenities: </b>
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div className="accordion-body">

                  <Amenities />

                </div>
              </div>
            </div>


            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  <b>Conectivity </b>
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <Conectivity />

                </div>
              </div>
            </div>

            {/* 
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  <b>6.	KANYA SIDDHI : কন্যা সিদ্ধি </b>
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <h6>1 মাস থেকে 10 বছর বয়স বাচ্চা মেয়ে   এর  জন্য
                    স্পেশাল স্কিমস. <br />
                    মেয়াদপূর্তিতে আকর্ষণীয় সুদের হার (সর্বোচ্চ মেয়াদপূর্তির সময়কাল 12 বছর
                  </h6>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <br />

      <div className="album py-1">
        <div className="container">
          <h3 style={{ color: "darkBlue" }}><b>OUR AMENITIES :</b></h3>
        </div>
      </div>
      <Amenities />
      <br />

      <div className="album py-1">
        <div className="container">
          <h3 style={{ color: "darkBlue" }}><b>WORK IN PROGRESS :</b></h3>
        </div>
      </div>
      <Workp />
      {/*    
      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
            <div className='prothamalifespace'>
                <img src={Prothamakrishnagar} className="card-img-top image10" alt="Prothamakrishnagar" />
                <div className="overlay">Prothama Lifespace</div>
                <div className="card-body">
                
              
              </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
            <div className='prothamalifespace'>
                <img src={Prothamaflat} className="card-img-top image10" alt="Prothamaflat" />
                <div className="overlay">Prothama Lifespace</div>
                <div className="card-body">
  
              </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}
      <br />
      {/* <div className="prothama_life-space">

        <div className="album py-0">
          <div className="container">
            <div className="row ">
              <div className="col-md-7" >
                <br />
                <br />
                <h1 className='mb-2'><b>PROTHAMA Life Space</b></h1>
                <h3 style={{ color: "white" }}><b>20+ Facility, Best Conectivity</b></h3>



              </div>
              <div className="col-md-5" >
                <br />
                <br />

              </div>
            </div>
          </div>
        </div>
      </div> */}



    </div>
  )
}

export default Home