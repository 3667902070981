import React from 'react'
import { Link } from "react-router-dom";
import prothamalogo from "../images/PROTHAMA Logo.jpg"
import "./Navbar.css";
import Modal from 'react-modal';
import AddEnquiry from '../AddEnquiry';


function Navbar() {
 
  return (
    <div>
     

      <div className='fixed-top'>

        <div className="lifespace">
          <div className="album py-1">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <a style={{ color: "white" }} className="btn-gradnav" href="tel: +91  9434054859"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-minus" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                    </svg><small> +91  94340 54859</small> </a>
                    <a style={{ color: "white" }} href='mailto: info@prothama.com'> <small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg> info@prothama.com</small></a>


                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
          <div className="container-fluid">
            <Link to="/">
              <img src={prothamalogo} className='me-2 mb-0' height="48px" width="270px" alt='prothama Rajprasad logo' />
              <p className='mt-0 mb-0' style={{ color: "darkblue" }}> <b>Prothama </b>Rajprasad</p>
              <div className='fonts'>
                <p className='mt-0 mb-0' style={{ color: "darkblue" }}> <small>An ISO 9001:2015 Certified Real Easted Company</small></p>
              </div>

            </Link>
            {/* <p className='mt-2' style={{ color: "grey" }} ><small>An ISO 9001:2015</small></p> */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-1 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" href="#">Home</Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/" className="nav-link" href="#">Flat Layout</Link>
                </li>
               */}

                <li className="nav-item">
                  <Link to="/Amenities" className="nav-link" href="#">Amenities</Link>
                </li>
                <li className="nav-item">
                  <Link to="/conectivity" className="nav-link" href="#">Conectivity</Link>
                </li>

                <li className="nav-item">
                  <Link to="/workin-progress" className="nav-link" href="#">Work in Progress</Link>
                </li>

                <li className="nav-item">
                  <Link to="/Contact-us" className="nav-link" href="#">Contact Us</Link>
                </li>
              </ul>

              <a href='tel: 9434054859'>
                <button className="buttoncallflat" type="submit">Call: +91 94340 54859 </button>
              </a>

            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar