import React from 'react'
import Navbar from '../inc/Navbar'
import AddBook from '../AddEnquiry'

function Formbook() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='flatkrishnagarnadianew'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-1">
                <div className="container">
                    <center>
                        <h5 style={{ color: "darkBlue" }} className='mb-2'>আপনার ভালোবাসার ঠিকানা  </h5>
                        <h4 style={{ color: "maroon" }}>ফ্ল্যাট  এর দামে আধুনিক জীবনযাপনের সমস্ত সুবিধা সহ রাজ প্রাসাদে বাস করুন। </h4>
                    </center>
                </div>

            </div>
            <AddBook />
            <br />
        </div>
    )
}

export default Formbook